import { Editor } from "@tinymce/tinymce-react";

export default function ExamSolutionBox({
  isShow,
  formModel,
  setFormModel,
  solutionRef
}) {
  return (
    <div
      className={"space-y-3 py-2 " + (isShow ? "" : " hidden ")}
    >

      <div className="px-5">
        <div className="text-left">
          Link Video Pembahasan
        </div>
        <div className="flex">
          <input
            className="w-full border-b border-gray-400 pb-2 outline-none"
            value={formModel.solutionVideoLink}
            onChange={e => setFormModel({ ...formModel, solutionVideoLink: e.target.value })}
          />
        </div>
      </div>
      <div className="px-5">
        <div className="text-left">
          Pembahasan
        </div>
        <div>
          <Editor
            apiKey="xh6ky1k77whs74aqd1wac0wzxmpdixv4hdoqw7bco18oo214"
            onInit={(evt, editor) => { solutionRef.current = editor }}
            initialValue={formModel.initSolutionText}
            init={{
              height: 400,
              menubar: false,
              external_plugins: { 'mathjax': '/ext_js/@dimakorotkov/tinymce-mathjax/plugin.min.js' },
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount'
              ],
              toolbar: 'code | undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | image | media | mathjax | help',
              mathjax: {
                lib: '/ext_js/mathjax/es5/tex-mml-chtml.js',
              },
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }',
              selector: 'textarea#file-picker',
              file_picker_types: 'image',
              automatic_uploads: true,
              /* and here's our custom image picker*/
              file_picker_callback: function (cb, value, meta) {
                var input = document.createElement('input');
                input.setAttribute('type', 'file');
                input.setAttribute('accept', 'image/*');

                input.onchange = function () {
                  var file = this.files[0];

                  var reader = new FileReader();
                  reader.onload = function () {
                    var id = 'blobid' + (new Date()).getTime();
                    var blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
                    var base64 = reader.result.split(',')[1];
                    var blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  };
                  reader.readAsDataURL(file);
                };

                input.click();
              }
            }}
          />
        </div>
      </div>

    </div>
  )
}